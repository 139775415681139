import { HOME_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHomeImages: builder.query({
      query: () => ({
        url: `${HOME_URL}/images`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetHomeImagesQuery } = homeApiSlice;
