//import { useEffect, useState } from "react";
import React, { useState } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import axios from "axios";
import { useGetHomeImagesQuery } from "../slices/homeApiSlice.js";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import ImageCarousel from "../components/ImageCarousel.tsx";
import HomeScreenPost from "../components/HomeScreenPost.tsx";
import WhatsNewPost from "../components/WhatsNewPost.tsx";

const HomeScreen = () => {
  //const [products, setProducts] = useState([]);
  //const { data: products, isLoading, error } = useGetProductsQuery({});
  const { data: homeImages, isLoading, error } = useGetHomeImagesQuery({});

  const bgDarkLightStyle = {
    background: "linear-gradient(to bottom, #0a1214 0%, #0e1b21 100%)",
  };

  const bgLightDarkStyle = {
    background: "linear-gradient(to bottom, #0e1b21 0%, #0a1214 100%)",
  };

  const bgDarkStyle = {
    background: "#0a1214",
  };

  const bgLightStyle = {
    background: "#0e1b21",
  };

  //useEffect(() => {
  //  const fetchProducts = async () => {
  //    const { data } = await axios.get("/api/products");
  //    setProducts(data);
  //  };

  //  fetchProducts();
  //}, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <div style={bgDarkLightStyle}>
            <Container className="py-4">
              <ImageCarousel
                carouselImages={homeImages.carouselImages}
              ></ImageCarousel>
            </Container>
          </div>

          <div style={bgDarkStyle}>
            <Container>
              <Row style={{ padding: "50px 0 50px 0" }}>
                <Col xs={12} md={4}>
                  <img
                    width="70%"
                    src="images/evija_about.jpg" // Replace with your image source
                    alt="Description of the image"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={8}>
                  <h1 className="display-6 py-3 my-0">
                    <strong style={{ fontWeight: "900" }}>
                      Prieks Tevi šeit redzēt!
                    </strong>
                  </h1>
                  <p>
                    Esmu <b>Evija</b> un radoši darbojos zem zīmola{" "}
                    <b>Geisha Art</b>, izpaužoties dažādos mākslas veidos. Šajā
                    lapā publicēju visus savus darbus un aktualitātes. Seko tiem
                    līdzi un iedvesmojies!
                  </p>
                  <p>
                    Galvenokārt, piedāvāju: Gleznas pēc numuriem, Karikatūras,
                    Sienu gleznojumus, Gleznas.Bet vienmēr esmu atvērta arī
                    citiem radošiem izaicinājumiem!
                  </p>
                  <Link to={`/parMani`}>
                    <Button
                      variant="primary"
                      className="custom-width-button"
                      style={{ margin: "30px 0 0 0", letterSpacing: "1px" }}
                    >
                      Uzzināt vairāk
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <div style={bgLightDarkStyle}>
            <Container>
              <Row style={{ padding: "50px 0 50px 0" }}>
                {homeImages.pakalpojumiImages.map((post) => (
                  <Col key={post._id} sm={12} md={6} lg={4} xl={3}>
                    <HomeScreenPost post={post} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div style={bgLightStyle}>
            <Container>
              <Row>
                {homeImages.whatsNewPosts.map((post) => (
                  <WhatsNewPost post={post}></WhatsNewPost>
                ))}
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;
