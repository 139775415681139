import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import FormContainer from "../../components/FormContainer.jsx";
import { toast } from "react-toastify";
import {
  useUpdateSienasGleznojumiMutation,
  useGetSienasGleznojumiByIdQuery,
  useUploadSienasGleznojumiImageMutation,
} from "../../slices/pakalpojumiApiSlice";

const SienasGleznojumiEditScreen = () => {
  const textAreaStyle = {
    backgroundColor: "#ccc8b8",
    color: "#0e1b21",
    letterSpacing: "1px",
    fontWeight: "bold",
  };

  const { id: sienasGleznojumsId } = useParams();

  const [image, setImage] = useState("");
  const [order, setOrder] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  const {
    data: sienasGleznojums,
    isLoading,
    refetch,
    error,
  } = useGetSienasGleznojumiByIdQuery(sienasGleznojumsId);

  const [updateSienasGleznojums, { isLoading: loadingUpdate }] =
    useUpdateSienasGleznojumiMutation();

  const [uploadSienasGleznojumsImage, { isLoading: loadingUpload }] =
    useUploadSienasGleznojumiImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (sienasGleznojums) {
      setImage(sienasGleznojums.image || "");
      setOrder(sienasGleznojums.order || 0);
      setTitle(sienasGleznojums.title || "");
      setDescription(sienasGleznojums.description || "");
      setIsPublished(sienasGleznojums.isPublished || false);
    }
  }, [sienasGleznojums]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedSienasGleznojums = {
      sienasGleznojumsId,
      sadala: "sienasGleznojumsId",
      image,
      title,
      description,
      isPublished,
      order,
    };

    const result = await updateSienasGleznojums(updatedSienasGleznojums);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sienas gleznojums ir saglabāts");
      refetch();
      navigate("/admin/sienuGleznojumiList");
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadSienasGleznojumsImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Container style={{ padding: "20px 12px 50px 12px" }}>
        <Link to="/admin/sienuGleznojumiList/">
          <Button
            className="btn btn-primary custom-width-button "
            variant="primary"
          >
            Atpakaļ
          </Button>
        </Link>

        <FormContainer>
          {loadingUpdate && <Loader />}
          <h1>Labot sienas gleznojumu</h1>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="order" className="my-2">
                <Form.Label>Sienas gleznojuma secība</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet sienas gleznojuma secību"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="image" className="my-2">
                <Form.Label>
                  Sienas gleznojuma attēla atrašanās lokācija
                </Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Attēla atrašanās vietu"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  style={textAreaStyle}
                  type="file"
                  placeholder="Choose file"
                  onChange={uploadFileHandler}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="width" className="my-2">
                <Form.Label>Sienas gleznojuma nosaukums</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Ievadiet sienas gleznojuma nosaukumu"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="height" className="my-2">
                <Form.Label>Sienas gleznojuma apraksts</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Ievadiet sienas gleznojuma aprakstu"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="isPublished" className="my-2">
                <Form.Label>Vai publicēt sienas gleznojumu?</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={isPublished}
                  onChange={(e) => setIsPublished(e.target.checked)}
                ></Form.Check>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                className="btn btn-primary custom-width-button my-2"
              >
                Saglabāt informāciju
              </Button>
            </Form>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

export default SienasGleznojumiEditScreen;
