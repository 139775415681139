import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { MdUnpublished, MdPublishedWithChanges } from "react-icons/md";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import { toast } from "react-toastify";
import {
  useGetKarikaturasImagesQuery,
  useCreateKarikaturaMutation,
  useUpdateIsPublishedKarikaturaByIdMutation,
  useDeleteKarikaturaByIdMutation,
} from "../../slices/pakalpojumiApiSlice";

const KarikaturasListScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: karikaturas,
    isLoading,
    error,
    refetch,
  } = useGetKarikaturasImagesQuery();

  const [createKarikatura, { isLoading: loadingCreate }] =
    useCreateKarikaturaMutation();

  const [updateIsPublished, { isLoading: loadingIsPublished }] =
    useUpdateIsPublishedKarikaturaByIdMutation();

  const [deleteKarikatura, { isLoading: loadingDelete }] =
    useDeleteKarikaturaByIdMutation();

  const createkarikaturasHandler = async () => {
    if (
      window.confirm(
        "Vai jūs tiešām vēlaties izveidot jaunu kAarikatūras elementu?"
      )
    ) {
      try {
        await createKarikatura();
        refetch();
      } catch (error) {
        toast.error(error?.data.message || error.message);
      }
    }
  };

  const publishHandler = async (id, isPublished) => {
    const data = {
      id: id,
      isPublished: isPublished,
    };

    if (window.confirm("Vai jūs tiešām vēlaties nopublicēt šo karikatūru?")) {
      const result = await updateIsPublished(data);
      if (result.error) {
        toast.error(result.error);
      } else {
        toast.success("Karikatūra ir nopublicēta");
        refetch();
      }
    }
  };

  const deleteHandler = async (id) => {
    if (
      window.confirm(
        "Vai jūs tiešām vēlaties izdzēst šo Gleznas pēc numuriem produktu?"
      )
    ) {
      var result = await deleteKarikatura(id);

      if (result.error) {
        toast.error(result.error?.data.error);
      } else {
        toast.success(result.data.message);
        refetch();
      }
    }
  };

  return (
    <>
      <Container style={{ padding: "50px 0 0 0" }}>
        <Row className="align-items-center">
          <Col>
            <h1 className="px-3 display-6">Karikatūras</h1>
          </Col>
          <Col className="text-end">
            <Button className="btn-sm m-3" onClick={createkarikaturasHandler}>
              <FaEdit /> Izveidot jaunu karikaturu
            </Button>
          </Col>
        </Row>
      </Container>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "20px 0 50px 0" }}>
            <Table striped hover responsive className="table-sm fw-bold">
              <thead>
                <tr>
                  <th>Attēls</th>
                  <th>Secība</th>
                  <th>Platums px</th>
                  <th>Augstums px</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {karikaturas.map((karikatura) => (
                  <tr key={karikatura._id}>
                    <td style={{ width: "300px" }}>
                      <img
                        src={karikatura.image}
                        style={{ width: "40%" }}
                        alt={`Karikatūra`}
                      />
                    </td>
                    <td>{karikatura.order}</td>
                    <td>{karikatura.width}</td>
                    <td>{karikatura.height}</td>
                    <td>
                      <div className="d-flex">
                        <LinkContainer
                          to={`/admin/karikaturas/${karikatura._id}/edit`}
                        >
                          <Button variant="light" className="btn-sm m-1">
                            <FaEdit />
                          </Button>
                        </LinkContainer>

                        <Button
                          className="fw-bold d-flex align-items-center btn-sm m-1"
                          variant={
                            karikatura.isPublished ? "success" : "warning"
                          }
                          onClick={() =>
                            publishHandler(
                              karikatura._id,
                              karikatura.isPublished
                            )
                          }
                        >
                          {karikatura.isPublished ? (
                            <MdPublishedWithChanges />
                          ) : (
                            <MdUnpublished />
                          )}
                          {karikatura.isPublished ? "Atpublicēt" : "Nopublicēt"}
                        </Button>

                        <Button
                          variant="danger"
                          className="btn-sm m-1"
                          onClick={() => deleteHandler(karikatura._id)}
                        >
                          <FaTrash style={{ color: "white" }} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </>
      )}
    </>
  );
};

export default KarikaturasListScreen;
