import React, { useEffect } from "react";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useGetGleznasPecNumuriemImagesQuery } from "../slices/pakalpojumiApiSlice.js";
import GleznasPecNumuriem from "../components/GleznasPecNumuriem.tsx";
import ContactForm from "../components/ContactForm.tsx";

const GleznasPecNumuriemScreen = () => {
  const {
    data: gleznasPecNumuriem,
    isLoading,
    error,
  } = useGetGleznasPecNumuriemImagesQuery({});

  const bgDarkLightStyle = {
    background: "linear-gradient(to bottom, #0a1214 0%, #0e1b21 100%)",
    padding: "0 0 50px 0",
  };

  const bgLightDarkStyle = {
    background: "linear-gradient(to bottom, #0e1b21 0%, #0a1214 100%)",
    padding: "70px 0 130px 0",
  };

  const bgDarkStyle = {
    background: "#0a1214",
  };

  const images = [
    {
      image: "/images/product_photo_1.jpg",
      height: 1200,
      width: 900,
      description: "Gleznas pēc numuriem - Citroni",
    },
    {
      image: "/images/product_photo_2.jpg",
      height: 1200,
      width: 900,
      description: "Gleznas pēc numuriem - Citroni",
    },
    {
      image: "/images/product_photo_3.jpg",
      height: 1200,
      width: 900,
      description: "Gleznas pēc numuriem - Citroni",
    },
    {
      image: "/images/product_photo_4.jpg",
      height: 1200,
      width: 900,
      description: "Gleznas pēc numuriem - Citroni",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <div style={bgDarkStyle}>
            <Container style={{ padding: "50px 0 50px 0" }}>
              <h1 className="px-3 display-4">Gleznas pēc numuriem</h1>
              <Row>
                <Col xs={12} md={12} xl={12} className="mx-auto">
                  <p className="px-3" style={{ textAlign: "justify" }}>
                    Mans jaunākais produkts ir <b>Gleznas pēc numuriem!</b>{" "}
                    Gleznas pēc numuriem ir lielisks veids kā praktiski jebkurš
                    var nonākt līdz
                    <b> paša radītam mākslas darbam</b>, kurš būs paša radīts –
                    tāpēc tik ļoti īpašs!
                  </p>
                  <p className="px-3" style={{ textAlign: "justify" }}>
                    <b>Gleznas pēc numuriem</b> var kalpot kā dāvana sev vai
                    saviem mīļajiem – nav svarīgi, tas ir bērns vai pieaugušais
                    – jebkurš var ļauties šim piedzīvojumam un mākslai.
                  </p>
                  <p className="px-3" style={{ textAlign: "justify" }}>
                    Tas ir ļoti relaksējošs un meditatīvs process, kurš prasa
                    mazliet koncentrēšanās un laika, bet tā visa gala rezultāts
                    būs neizsakāms gandarījums par paveikto!
                  </p>

                  <Row className="p-4">
                    <Gallery>
                      {images.map((item, index) => (
                        <Col key={index} sm={12} md={3} lg={3} xl={3}>
                          <Item
                            cropped
                            original={item.image}
                            thumbnail={item.image}
                            width={item.width}
                            height={item.height}
                            alt={item.description}
                          >
                            {({ ref, open }) => (
                              <img
                                style={{
                                  objectFit: "contain",
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                                  margin: "0 0 20px 0",
                                }}
                                loading="lazy"
                                src={item.image}
                                ref={
                                  ref as React.MutableRefObject<HTMLImageElement>
                                }
                                onClick={open}
                                alt={item.description}
                              />
                            )}
                          </Item>
                        </Col>
                      ))}
                    </Gallery>
                  </Row>

                  <p className="px-3" style={{ textAlign: "justify" }}>
                    Pašlaik <b>Gleznas pēc numuriem</b> ir pieejami zemāk esošie
                    produkti.
                  </p>
                  <p className="px-3" style={{ textAlign: "justify" }}>
                    <b>Kā pasūtīt?</b> Spied zemāk esošo pogu <b>PASŪTĪT</b>,
                    tekstā norādot produkta nosaukumu un cik gabalus vēlies.
                    Saņemot ziņu no Tevis, es privāti ar Tevi sazināšos, lai
                    pabeigtu pasūtījumu! Vai arī - sazinies ar mani{" "}
                    <b>WhatsApp!</b>
                  </p>
                </Col>
              </Row>

              <Row className="g-2 px-3 my-3">
                <Gallery>
                  {gleznasPecNumuriem
                    .filter((item) => item.isPublished == true)
                    .map((item) => (
                      <GleznasPecNumuriem item={item}></GleznasPecNumuriem>
                    ))}
                </Gallery>
              </Row>
            </Container>
          </div>

          <div style={bgLightDarkStyle}>
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} md={6} className="mx-auto">
                  <div id="contactFormSection">
                    <p
                      style={{
                        paddingLeft: "1.2rem",
                        paddingTop: "30px",
                        marginBottom: "30px",
                      }}
                    >
                      Lai veiktu pasūtījumu lūdzu aizpildiet Pasūtījuma formu!
                    </p>
                    <h1 className="px-3 display-6">Pasūtījuma forma</h1>
                    <ContactForm
                      papildusHeader="(produkta nosaukums un skaits)"
                      isOrderForm={true}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default GleznasPecNumuriemScreen;
