import React from "react";
import { Card, Col, Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Gallery, Item } from "react-photoswipe-gallery";

const GleznasPecNumuriem = ({ item }) => {
  const scrollToContactForm = () => {
    const contactFormSection = document.getElementById("contactFormSection");
    if (contactFormSection) {
      contactFormSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Col key={item._id} sm={12} md={6} lg={3} xl={3}>
      <Card className="p-1 rounded post-bg">
        <Card.Body>
          <Item
            original={item.image}
            thumbnail={item.image}
            width="900"
            height="1200"
            alt={item.description}
          >
            {({ ref, open }) => (
              <img
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "auto",
                  margin: "0 0 10px 0",
                }}
                src={item.image}
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                alt={item.description}
              />
            )}
          </Item>
          <Card.Title as="div" className="gpn-title text-color">
            <strong>{item.title}</strong>
          </Card.Title>
          <Card.Text as="div" className="gpn-text text-color">
            Cena:{" "}
            {item.price.toLocaleString("lv-LV", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Card.Text>
          <Card.Text as="div" className="gpn-text text-color">
            Izmērs: {item.size}
          </Card.Text>
          <Card.Text
            as="div"
            className="gpn-text text-color"
            style={{ padding: "0 0 20px 0" }}
          >
            Krāsu skaits: {item.colors}
          </Card.Text>

          <Button
            style={{
              width: "100%",
              marginBottom: "10px",
              textAlign: "center",
              letterSpacing: "1px",
            }}
            variant="primary"
            onClick={scrollToContactForm}
          >
            Pasūtīt
          </Button>
          <Accordion className="p-0">
            <Accordion.Item eventKey="0" className="accordion-text text-color">
              <Accordion.Header>Vairāk info</Accordion.Header>
              <Accordion.Body>{item.description}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default GleznasPecNumuriem;
