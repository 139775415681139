import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const ImageCarousel = ({ carouselImages }) => {
  const imageStyle = {
    maxHeight: "100%", // Adjust the height as needed
    width: "100%", // Adjust the width as needed
    objectFit: "cover", // Ensure the image covers the container
    padding: "0 10px 0 0",
  };

  const iconStyle = {
    fontSize: "2em", // Adjust the size as needed
    padding: "0 0 0 0",
  };

  const handleDragStart = (e) => e.preventDefault();

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <>
      <AliceCarousel
        controlsStrategy="alternate"
        infinite
        mouseTracking
        keyboardNavigation
        disableDotsControls
        responsive={responsive}
        renderPrevButton={() => {
          return (
            <Button
              variant="outline-secondary"
              className="button-custom rounded-circle"
              size="sm"
            >
              <FaAngleLeft style={iconStyle} />
            </Button>
          );
        }}
        renderNextButton={() => {
          return (
            <div style={{ padding: "15px 0 0 10px" }}>
              <Button
                variant="outline-secondary"
                className="button-custom rounded-circle"
                size="sm"
              >
                <FaAngleRight style={iconStyle} />
              </Button>
            </div>
          );
        }}
        items={carouselImages.map((item, index) => (
          <Image key={index} src={item.image} fluid style={imageStyle} />
        ))}
      />
    </>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default ImageCarousel;
