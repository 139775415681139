import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useGetGleznasImagesQuery } from "../slices/pakalpojumiApiSlice.js";
import Gleznas from "../components/Gleznas.tsx";

const GleznasScreen = () => {
  const { data: gleznas, isLoading, error } = useGetGleznasImagesQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <h1 className="px-3 display-4">Gleznas</h1>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Gleznoju arī <b>gleznas</b> – dažādos stilos, izmēros,
              kombinācijās. Iespējams iegādāties gan jau gatavas gleznas, gan
              arī <b>pasūtīt personalizētu gleznu</b> pēc Tavām velmēm.
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Daļa no gleznām jau dzīvo savās jaunajās mājās, bet daļa no tām
              vēl meklē mājas.
            </p>
            <h5 className="px-3 display-6">Pieejamās gleznas:</h5>

            <Row className="g-2 px-3 my-3">
              {gleznas
                .filter((glezna) => glezna.isAvailable)
                .map((item) => (
                  <Gleznas item={item}></Gleznas>
                ))}
            </Row>

            <h5 className="px-3 display-6">Ne-pieejamās gleznas:</h5>

            <Row className="g-2 px-3 my-3">
              {gleznas
                .filter((glezna) => !glezna.isAvailable)
                .map((item) => (
                  <Gleznas item={item}></Gleznas>
                ))}
            </Row>

            <p className="px-3" style={{ textAlign: "justify" }}>
              Par <b>cenām</b> – jautāt privāti.
            </p>
          </Container>
        </>
      )}
    </>
  );
};

export default GleznasScreen;
