import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { MdUnpublished, MdPublishedWithChanges } from "react-icons/md";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import { toast } from "react-toastify";
import {
  useGetGleznasPecNumuriemImagesQuery,
  useCreateGleznaPecNrMutation,
  useDeleteGleznaPecNrByIdMutation,
  useUpdateIsPublishedGleznaPecNrByIdMutation,
} from "../../slices/pakalpojumiApiSlice";

const GleznasPecNumuriemListScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: gleznas,
    isLoading,
    error,
    refetch,
  } = useGetGleznasPecNumuriemImagesQuery();

  const [createGleznaPecNr, { isLoading: loadingCreate }] =
    useCreateGleznaPecNrMutation();

  const [deleteGleznaPecNr, { isLoading: loadingDelete }] =
    useDeleteGleznaPecNrByIdMutation();

  const [updateIsPublished, { isLoading: loadingIsPublished }] =
    useUpdateIsPublishedGleznaPecNrByIdMutation();

  const deleteHandler = async (id) => {
    if (
      window.confirm(
        "Vai jūs tiešām vēlaties izdzēst šo Gleznas pēc numuriem produktu?"
      )
    ) {
      var result = await deleteGleznaPecNr(id);

      if (result.error) {
        toast.error(result.error?.data.error);
      } else {
        toast.success(result.data.message);
        refetch();
      }
    }
  };

  const publishHandler = async (id, isPublished) => {
    const data = {
      id: id,
      isPublished: isPublished,
    };

    if (
      window.confirm(
        "Vai jūs tiešām vēlaties nopublicēt šo Gleznas pēc numuriem produktu?"
      )
    ) {
      const result = await updateIsPublished(data);
      if (result.error) {
        toast.error(result.error);
      } else {
        toast.success("Glezna pēc numuriem ir nopublicēta");
        refetch();
      }
    }
  };

  const createGleznasPecNrHandler = async () => {
    if (
      window.confirm(
        "Vai jūs tiešām vēlaties izveidot jaunu Gleznas pēc numuriem produktu?"
      )
    ) {
      try {
        await createGleznaPecNr();
        refetch();
      } catch (error) {
        toast.error(error?.data.message || error.message);
      }
    }
  };

  return (
    <>
      <Container style={{ padding: "50px 0 0 0" }}>
        <Row className="align-items-center">
          <Col>
            <h1 className="px-3 display-6">Gleznas pēc numuriem</h1>
          </Col>
          <Col className="text-end">
            <Button className="btn-sm m-3" onClick={createGleznasPecNrHandler}>
              <FaEdit /> Izveidot jaunu gleznu
            </Button>
          </Col>
        </Row>
      </Container>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "20px 0 50px 0" }}>
            <Table striped hover responsive className="table-sm fw-bold">
              <thead>
                <tr>
                  {/*<th>Sadaļa</th>
                    <th>Atrašanās</th>*/}
                  <th>Attēls</th>
                  <th>Secība</th>
                  <th>Nosaukums</th>
                  <th>Cena</th>
                  <th>Krāsu skaits</th>
                  <th>Izmērs</th>
                  <th>Apraksts</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {gleznas.map((glezna) => (
                  <tr key={glezna._id}>
                    {/*<td>{glezna.sadala}</td>
                      <td>{glezna.image}</td>*/}
                    <td>
                      <img
                        src={glezna.image}
                        style={{ width: "50%" }}
                        alt={`Glezna ${glezna.title}`}
                      />
                    </td>
                    <td>{glezna.order}</td>
                    <td>{glezna.title}</td>
                    <td>{glezna.price}</td>
                    <td>{glezna.colors}</td>
                    <td>{glezna.size}</td>
                    <td>{glezna.description}</td>
                    <td>
                      <div className="d-flex">
                        <LinkContainer
                          to={`/admin/gleznasPecNr/${glezna._id}/edit`}
                        >
                          <Button variant="light" className="btn-sm m-1">
                            <FaEdit />
                          </Button>
                        </LinkContainer>

                        <Button
                          className="fw-bold d-flex align-items-center btn-sm m-1"
                          variant={glezna.isPublished ? "success" : "warning"}
                          onClick={() =>
                            publishHandler(glezna._id, glezna.isPublished)
                          }
                        >
                          {glezna.isPublished ? (
                            <MdPublishedWithChanges />
                          ) : (
                            <MdUnpublished />
                          )}
                          {glezna.isPublished ? "Atpublicēt" : "Nopublicēt"}
                        </Button>

                        <Button
                          variant="danger"
                          className="btn-sm m-1"
                          onClick={() => deleteHandler(glezna._id)}
                        >
                          <FaTrash style={{ color: "white" }} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </>
      )}
    </>
  );
};

export default GleznasPecNumuriemListScreen;
