import React, { useState } from "react";
import { Form, Button, Alert, Col } from "react-bootstrap";
import Loader from "../components/Loader.tsx";

const ContactForm = ({ papildusHeader, isOrderForm = false }) => {
  const [state, setState] = useState({
    name: "",
    from: "",
    number: "",
    subject: `Klienta ziņa no Geeishaart mājaslapas kontaktformas`,
    message: "",
    formSubmitted: false,
    isGDPRChecked: false,
  });

  const { name, from, number, message, isGDPRChecked } = state;

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckbox = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const textAreaStyle = {
    backgroundColor: "#ccc8b8",
    color: "#0e1b21",
    letterSpacing: "1px",
    fontWeight: "bold",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, from, number, subject, message, isGDPRChecked } = state;

    if (isGDPRChecked) {
      try {
        setIsLoading(true);
        const response = await fetch("/api/sendmail", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, from, number, subject, message }),
        });
        if (response.status === 200) {
          console.log("Email sent!. \nResponse:", response);
          setState((prevState) => ({
            ...prevState,
            name: "",
            from: "",
            number: "",
            message: "",
            formSubmitted: true,
            isGDPRChecked: false,
          }));
        } else {
          console.log("Email not sent. \nResponse:", response);
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
      setIsLoading(false);
    } else {
      alert("Please accept GDPR rules.");
    }
  };

  return (
    <Form className="px-3" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Jūsu vārds</Form.Label>
        <Form.Control
          id="nameId"
          style={textAreaStyle}
          required
          type="text"
          name="name"
          value={name}
          onChange={handleChange}
          placeholder="Vārds"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>E-pasta adrese</Form.Label>
        <Form.Control
          style={textAreaStyle}
          required
          type="email"
          name="from"
          value={from}
          onChange={handleChange}
          placeholder="Epasts"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicTalrunis">
        <Form.Label>Kontakttālrunis</Form.Label>
        <Form.Control
          style={textAreaStyle}
          type="text"
          name="number"
          value={number}
          onChange={handleChange}
          placeholder="Kontakttālrunis"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicZinojums">
        <Form.Label>Ziņojums {papildusHeader}</Form.Label>
        <Form.Control
          style={textAreaStyle}
          name="message"
          value={message}
          onChange={handleChange}
          as="textarea"
          rows={3}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckBox">
        <Form.Check
          type="checkbox"
          name="isGDPRChecked"
          label="Piekrītu Vispārējās datu aizsardzības regulas prasībām (GDPR), ka mani dati tiks apstrādāti."
          checked={isGDPRChecked}
          onChange={handleCheckbox}
          required
        />
      </Form.Group>

      {isOrderForm && (
        <Form.Group className="mb-3" controlId="formBasicZinojums">
          <Form.Label>
            Sūtu izmantojot Omniva pakomātu, kas būs papildus 3,09 EUR
          </Form.Label>
        </Form.Group>
      )}

      {isLoading ? (
        <Loader />
      ) : !state.formSubmitted ? (
        <Button
          style={{ letterSpacing: "1px" }}
          className="mt-2 custom-width-button"
          variant="primary"
          type="submit"
        >
          Sūtīt
        </Button>
      ) : (
        <>
          <Alert
            key="success"
            variant="success"
            style={{ color: "#0e1b21", fontWeight: "bold" }}
          >
            Ziņojums veiksmīgi nosūtīts!
          </Alert>
          <Button
            style={{ letterSpacing: "1px" }}
            className="mt-2 custom-width-button"
            variant="primary"
            type="submit"
          >
            Sūtīt
          </Button>
        </>
      )}
    </Form>
  );
};

export default ContactForm;
