import React from "react";
import { Card, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Gallery, Item } from "react-photoswipe-gallery";
import { PAKAPLOJUMI_URL } from "../constants";

const Gleznas = ({ item }) => {
  return (
    //<Gallery>
    <Col key={item._id} sm={12} md={6} lg={4} xl={4}>
      <Card className="p-1 rounded post-bg">
        <Link to={`/pakalpojumi/gleznas/${item._id}`}>
          <Card.Img
            className="rounded-0"
            src={item.images[0].path}
            variant="top"
          />
        </Link>

        <Card.Body>
          <Link to={`/pakalpojumi/gleznas/${item._id}`}>
            <Card.Title as="div" className="gpn-title text-color">
              <strong>{item.title}</strong>
            </Card.Title>
          </Link>
          {item.price && (
            <Card.Text as="div" className="gpn-text text-color">
              Cena:{" "}
              {item.price.toLocaleString("lv-LV", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Col>
    //</Gallery>
  );
};

export default Gleznas;
