import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhatsNewPost = ({ post }) => {
  const cardImageStyle = {
    width: "100%", // Adjust the width as needed
    height: "100%", // Adjust the height as needed
    objectFit: "cover", // Ensure the image covers the container
    //objectFit: "scale-down", // Ensure the image covers the container
  };

  return (
    <Card className="my-3 rounded post-bg">
      <Card.Body style={{}}>
        <Row>
          <Col xs={12} md={6} className="flex-wrap">
            {" "}
            <Card.Title
              style={{
                textAlign: "left",
                fontSize: "30px",
                fontWeight: "bold",
              }}
              className="post-title text-color my-2"
            >
              {post.title}
            </Card.Title>
            <Card.Title
              style={{ textAlign: "left", fontWeight: "bold" }}
              className="post-title text-color py-3"
            >
              {post.subTitle}
            </Card.Title>
            <Card.Text
              style={{ textAlign: "justify", padding: "30px 20px 0 0" }}
            >
              {post.description}
            </Card.Text>
            <Link to={`/pakalpojumi${post.link}`}>
              <Button
                className="custom-width-button"
                variant="primary"
                style={{ margin: "30px 0 0 0" }}
              >
                Uzzināt vairāk
              </Button>
            </Link>
          </Col>
          <Col xs={12} md={3} style={{ alignItems: "center" }}>
            <Card.Img
              src={post.imageO}
              className="rounded-0 img-fluid py-3"
              alt={post.title}
              style={cardImageStyle}
            />
          </Col>
          <Col xs={12} md={3} style={{ alignItems: "center" }}>
            <Card.Img
              src={post.imageT}
              className="rounded-0 img-fluid py-3"
              alt={post.title}
              style={cardImageStyle}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WhatsNewPost;
