import React, { useEffect } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import ContactForm from "../components/ContactForm.tsx";
import { Col, Container, Row } from "react-bootstrap";

const KontaktiScreen = () => {
  const iconStyle = {
    fontSize: "3em", // Adjust the size as needed
    padding: "0 0 0 20",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={{ padding: "50px 0 50px 0" }}>
        <Row>
          <Col xs={12} md={6}>
            <h1 className="px-3 display-4">Kontakti</h1>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Ja esi ieinteresēts manā mākslā vai vienkārši vēlies sazināties un
              uzdot sev interesējošus jautājums, droši sazinies ar mani sev
              ērtākajā veidā!
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Saziņai un pasūtījumiem:</b>
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Saziņas forma vai
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              E-pasts: geeishaart@gmail.com
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Kontakttālrunis: +371 2247 4650
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Sociālie tīkli:
              <a href="https://www.facebook.com/geeishaart">
                <FaFacebook
                  style={{ fontSize: "40px", padding: "0 0px 0 10px" }}
                />
              </a>
              <a href="https://www.instagram.com/geeisha_art/">
                <FaInstagram
                  style={{ fontSize: "40px", padding: "0 0px 0 10px" }}
                />
              </a>
            </p>
          </Col>
          <Col xs={12} md={6}>
            <h1 className="px-3 display-6">Saziņas forma</h1>
            <ContactForm></ContactForm>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KontaktiScreen;
