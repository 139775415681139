import { Container, Row, Col, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";

import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerStyle = {
    backgroundColor: "#0a1214",
  };
  
  return (
    <footer style={footerStyle}>
      <Container style={{ padding: "50px 0 10px 0" }}>
        <Row>
          <Col xs={12} md={4} className="text-left px-4 py-3">
            <h1 style={{ fontSize: "1.5rem" }} className="display-6">
              <b>Geisha Art</b>
            </h1>
            <p style={{ textAlign: "justify" }}>
              Aiz zīmola <b>Geisha Art</b> stāv māksliniece <b>Evija Keiša</b>, kas izkopj
              dažādus mākslas veidus un piedāvā tos šeit apskatīt plašākai
              publikai. Iedvesmojies un ļauj mākslai ienākt arī Tavā dzīvē!{" "}
            </p>
          </Col>
          <Col xs={12} md={4} className="text-left px-4  py-3">
            <h1 style={{ fontSize: "1.5rem" }} className="display-6">
              <b>Pakalpojumi</b>
            </h1>
            <LinkContainer to="/pakalpojumi/gleznPecNum">
              <Nav.Link>Gleznas pēc numuriem</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/pakalpojumi/karikaturas">
              <Nav.Link>Karikatūras</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/pakalpojumi/sienuGleznojumi">
              <Nav.Link>Sienu gelznojumi</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/pakalpojumi/gleznas">
              <Nav.Link>Gleznas</Nav.Link>
            </LinkContainer>
          </Col>
          <Col xs={12} md={4} className="text-left px-4  py-2">
            <a href="https://www.facebook.com/geeishaart">
              <FaFacebook style={{ fontSize: "40px", padding: "0 10px 0 0px" }} />
            </a>
            <a href="https://www.instagram.com/geeisha_art/">
              <FaInstagram style={{ fontSize: "40px", padding: "0 0px 0 10px" }} />
            </a>
            <p style={{ padding: "10px 0 0px 0" }}>
              E-pasts: geeishaart@gmail.com
            </p>
            <p>Kontakttālrunis: +371 2247 4650</p>
          </Col>
          <Col className="text-center py-0">
            <p style={{ padding: "70px 0 10px 0" }}>
              Geisha Art &copy; {currentYear}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
