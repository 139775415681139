//export const BASE_URL =
//  process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

export const BASE_URL = "";
export const HOME_URL = "/api/home";
export const PAKAPLOJUMI_URL = "/api/pakalpojumi";
export const USERS_URL = "/api/users";
export const ATSAUKSMES_URL = "/api/atsauksmes";
export const UPLOAD_URL = "/api/upload";

export const PRODUCTS_URL = "/api/products";
export const ORDERS_URL = "/api/orders";
export const PAYPAL_URL = "/api/config/paypal";
