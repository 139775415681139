import React, { useEffect } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGetReviewsQuery } from "../slices/reviewApiSlice.js";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";

const ReviewScreen = () => {
  const { data: reviews, isLoading, error } = useGetReviewsQuery({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Container style={{ padding: "50px 0 50px 0" }}>
          <h1 style={{ padding: "0px 0 20px 0" }} className="px-3 display-4">
            Atsauksmes
          </h1>
          <Row>
            {reviews.map((post) => (
              <Col key={post._id} sm={12} md={6} lg={4} xl={4}>
                <Card className="review-card">
                  <Card.Body>
                    <div className="speech-bubble">
                      <strong className="review-text">{post.comment}</strong>
                    </div>
                    <Card.Text className="reviewer-info">
                      <Row>
                        <Col
                          style={{ flex: "0 0 50px", padding: "0 0 0 10px" }}
                        >
                          <FaRegUserCircle style={{ fontSize: "70px", padding: "0 20px 0 10px" }} />
                        </Col>
                        <Col style={{ flex: "0 0 50%", padding: "10px 0 0 0", letterSpacing: '1px' }}>
                          <strong>
                            {post.name},<br /> {post.date}
                          </strong>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default ReviewScreen;
