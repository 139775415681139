import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Row, NavDropdown } from "react-bootstrap";
import { FaShoppingCart, FaUser, FaUserGraduate } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../assets/logo_web_100.png";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";

const Header = () => {
  const navbarStyle = {
    background: "linear-gradient(to bottom, #0e1b21 0%, #0a1214 100%)",
  };

  const menustyle = {
    background: "#0e1b21",
  };

  const navLink = {
    //borderRight: "2px solid #0a1214",
    margin: "0 10px 0 10px",
  };

  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header>
      <Navbar style={navbarStyle} variant="dark" expand="md" collapseOnSelect>
        <Container className="text-center d-flex flex-column">
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={logo} alt="Geisha Art"></img>
              <div
                className="text-color"
                style={{
                  fontFamily: "'Beauty_Ballerina', sans-serif",
                  fontSize: 30,
                  letterSpacing: "3px",
                }}
              >
                GEISHA ART
              </div>
            </Navbar.Brand>
          </LinkContainer>
        </Container>
      </Navbar>
      <Navbar style={menustyle} variant="dark" expand="md" collapseOnSelect>
        <Container className="text-center d-flex flex-column">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer style={navLink} to="/">
                <Nav.Link>Sākums</Nav.Link>
              </LinkContainer>

              <NavDropdown
                style={navLink}
                title="Pakalpojumi"
                id="basic-nav-dropdown"
              >
                <LinkContainer style={navLink} to="/pakalpojumi/gleznPecNum">
                  <NavDropdown.Item>Gleznas pēc numuriem</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer style={navLink} to="/pakalpojumi/karikaturas">
                  <NavDropdown.Item>Karikatūras</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  style={navLink}
                  to="/pakalpojumi/sienuGleznojumi"
                >
                  <NavDropdown.Item>Sienu gleznojumi</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer style={navLink} to="/pakalpojumi/gleznas">
                  <NavDropdown.Item>Gleznas</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer style={navLink} to="/parMani">
                <Nav.Link>Par mani</Nav.Link>
              </LinkContainer>

              <LinkContainer style={navLink} to="/atsauksmes">
                <Nav.Link>Atsauksmes</Nav.Link>
              </LinkContainer>

              <LinkContainer style={navLink} to="/kontakti">
                <Nav.Link>Kontakti</Nav.Link>
              </LinkContainer>

              {/*<LinkContainer to="/cart">
                <Nav.Link>
                  <FaShoppingCart /> Cart
                </Nav.Link>
              </LinkContainer>*/}

              {userInfo && (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer style={navLink} to="/profile">
                    <NavDropdown.Item>Profils</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Izrakstīties
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title="Admin" id="adminMenu">
                  <LinkContainer style={navLink} to="/admin/gleznasPecNrList">
                    <NavDropdown.Item>
                      Gleznas pēc numuriem saraksts
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer style={navLink} to="/admin/karikaturasList">
                    <NavDropdown.Item>Karikatūras saraksts</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    style={navLink}
                    to="/admin/sienuGleznojumiList"
                  >
                    <NavDropdown.Item>
                      Sienas gleznojumu saraksts
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
