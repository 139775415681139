import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { MdUnpublished, MdPublishedWithChanges } from "react-icons/md";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import { toast } from "react-toastify";
import {
  useGetSienasGleznojumiImagesQuery,
  useCreateSienasGleznojumiMutation,
  useUpdateIsPublishedSienasGleznojumiByIdMutation,
  useDeleteSienasGleznojumiByIdMutation,
} from "../../slices/pakalpojumiApiSlice";

const SienasGleznojumiListScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: sienasGleznojumi,
    isLoading,
    error,
    refetch,
  } = useGetSienasGleznojumiImagesQuery();

  const [createSienasGleznojums, { isLoading: loadingCreate }] =
    useCreateSienasGleznojumiMutation();

  const [updateIsPublished, { isLoading: loadingIsPublished }] =
    useUpdateIsPublishedSienasGleznojumiByIdMutation();

  const [deleteSienasGleznojums, { isLoading: loadingDelete }] =
    useDeleteSienasGleznojumiByIdMutation();

  const createsienasGleznojumsHandler = async () => {
    if (
      window.confirm(
        "Vai jūs tiešām vēlaties izveidot jaunu kAarikatūras elementu?"
      )
    ) {
      try {
        await createSienasGleznojums();
        refetch();
      } catch (error) {
        toast.error(error?.data.message || error.message);
      }
    }
  };

  const publishHandler = async (id, isPublished) => {
    const data = {
      id: id,
      isPublished: isPublished,
    };

    if (
      window.confirm("Vai jūs tiešām vēlaties nopublicēt šo sienas gleznojumu?")
    ) {
      const result = await updateIsPublished(data);
      if (result.error) {
        toast.error(result.error);
      } else {
        toast.success("Sienas gleznojums ir nopublicēta");
        refetch();
      }
    }
  };

  const deleteHandler = async (id) => {
    if (
      window.confirm("Vai jūs tiešām vēlaties izdzēst šo sienas gleznojumu?")
    ) {
      var result = await deleteSienasGleznojums(id);

      if (result.error) {
        toast.error(result.error?.data.error);
      } else {
        toast.success(result.data.message);
        refetch();
      }
    }
  };

  return (
    <>
      <Container style={{ padding: "50px 0 0 0" }}>
        <Row className="align-items-center">
          <Col>
            <h1 className="px-3 display-6">Karikatūras</h1>
          </Col>
          <Col className="text-end">
            <Button
              className="btn-sm m-3"
              onClick={createsienasGleznojumsHandler}
            >
              <FaEdit /> Izveidot jaunu sienas gleznojumu
            </Button>
          </Col>
        </Row>
      </Container>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "20px 0 50px 0" }}>
            <Table striped hover responsive className="table-sm fw-bold">
              <thead>
                <tr>
                  <th>Attēls</th>
                  <th>Secība</th>
                  <th>Nosaukums</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sienasGleznojumi.map((sieansGleznojums) => (
                  <tr key={sieansGleznojums._id}>
                    <td style={{ width: "300px" }}>
                      <img
                        src={sieansGleznojums.image}
                        style={{ width: "40%" }}
                        alt={`Sienas gleznojums`}
                      />
                    </td>
                    <td>{sieansGleznojums.order}</td>
                    <td>{sieansGleznojums.title}</td>
                    <td>
                      <div className="d-flex">
                        <LinkContainer
                          to={`/admin/sienuGleznojumi/${sieansGleznojums._id}/edit`}
                        >
                          <Button variant="light" className="btn-sm m-1">
                            <FaEdit />
                          </Button>
                        </LinkContainer>

                        <Button
                          className="fw-bold d-flex align-items-center btn-sm m-1"
                          variant={
                            sieansGleznojums.isPublished ? "success" : "warning"
                          }
                          onClick={() =>
                            publishHandler(
                              sieansGleznojums._id,
                              sieansGleznojums.isPublished
                            )
                          }
                        >
                          {sieansGleznojums.isPublished ? (
                            <MdPublishedWithChanges />
                          ) : (
                            <MdUnpublished />
                          )}
                          {sieansGleznojums.isPublished
                            ? "Atpublicēt"
                            : "Nopublicēt"}
                        </Button>

                        <Button
                          variant="danger"
                          className="btn-sm m-1"
                          onClick={() => deleteHandler(sieansGleznojums._id)}
                        >
                          <FaTrash style={{ color: "white" }} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </>
      )}
    </>
  );
};

export default SienasGleznojumiListScreen;
