import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./components/Header.tsx";
import Footer from "./components/Footer.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const bgDarkLightStyle = {
    background: "linear-gradient(to bottom, #0a1214 0%, #0e1b21 100%)",
  };

  return (
    <>
      <Header />
      <main style={bgDarkLightStyle} className="custom-main text-color">
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default App;
