import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import FormContainer from "../../components/FormContainer.jsx";
import { toast } from "react-toastify";
import {
  useUpdateKarikaturaMutation,
  useGetKarikaturaByIdQuery,
  useUploadKarikaturaImageMutation,
} from "../../slices/pakalpojumiApiSlice";

const KarikaturasEditScreen = () => {
  const textAreaStyle = {
    backgroundColor: "#ccc8b8",
    color: "#0e1b21",
    letterSpacing: "1px",
    fontWeight: "bold",
  };

  const { id: karikaturasId } = useParams();

  const [image, setImage] = useState("");
  const [order, setOrder] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isPublished, setIsPublished] = useState(false);

  const {
    data: karikatura,
    isLoading,
    refetch,
    error,
  } = useGetKarikaturaByIdQuery(karikaturasId);

  const [updateKarikatura, { isLoading: loadingUpdate }] =
    useUpdateKarikaturaMutation();

  const [uploadKarikaturaImage, { isLoading: loadingUpload }] =
    useUploadKarikaturaImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (karikatura) {
      setImage(karikatura.image || "");
      setOrder(karikatura.order || 0);
      setWidth(karikatura.width || 0);
      setHeight(karikatura.height || "");
      setIsPublished(karikatura.isPublished || false);
    }
  }, [karikatura]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedKarikatura = {
      karikaturasId,
      sadala: "karikaturas",
      image,
      width,
      height,
      isPublished,
      order,
    };

    const result = await updateKarikatura(updatedKarikatura);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Karikatūra ir saglabāta");
      refetch();
      navigate("/admin/karikaturasList");
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadKarikaturaImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Container style={{ padding: "20px 12px 50px 12px" }}>
        <Link to="/admin/karikaturasList/">
          <Button
            className="btn btn-primary custom-width-button "
            variant="primary"
          >
            Atpakaļ
          </Button>
        </Link>

        <FormContainer>
          {loadingUpdate && <Loader />}
          <h1>Labot karikatūru</h1>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="order" className="my-2">
                <Form.Label>Karikatūru secība</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet karikatūras secību"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="image" className="my-2">
                <Form.Label>Karikatūras attēla atrašanās lokācija</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Attēla atrašanās vietu"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  style={textAreaStyle}
                  type="file"
                  placeholder="Choose file"
                  onChange={uploadFileHandler}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="width" className="my-2">
                <Form.Label>Karikatūras platums (px)</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet karikatūras platumu px"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="height" className="my-2">
                <Form.Label>Karikatūras augstums (px)</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet karikatūras augstumu"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="isPublished" className="my-2">
                <Form.Label>Vai publicēt gleznu?</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={isPublished}
                  onChange={(e) => setIsPublished(e.target.checked)}
                ></Form.Check>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                className="btn btn-primary custom-width-button my-2"
              >
                Saglabāt informāciju
              </Button>
            </Form>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

export default KarikaturasEditScreen;
