import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useGetGleznasImagesByIdQuery } from "../slices/pakalpojumiApiSlice.js";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import { Row, Col, Container, Button } from "react-bootstrap";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const GleznasDetailsScreen = () => {
  const { id: gleznasId } = useParams();
  const {
    data: glezna,
    isLoading,
    error,
  } = useGetGleznasImagesByIdQuery(gleznasId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <Row className="g-3 px-3 my-3 align-items-start">
              <Link to="/pakalpojumi/gleznas">
                <Button
                  className="custom-width-button btn btn-primary"
                  variant="primary"
                >
                  Atpakaļ
                </Button>
              </Link>
              <Gallery>
                {glezna.images.map((item) => (
                  <Col key={item._id} sm={12} md={6} lg={4} xl={4}>
                    <Item
                      cropped
                      original={item.path}
                      thumbnail={item.path}
                      width={item.width}
                      height={item.height}
                      alt={glezna.description}
                    >
                      {({ ref, open }) => (
                        <img
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                            margin: "0 0 10px 0",
                          }}
                          src={item.path}
                          ref={ref as React.MutableRefObject<HTMLImageElement>}
                          onClick={open}
                          alt={glezna.description}
                        />
                      )}
                    </Item>
                  </Col>
                ))}
              </Gallery>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default GleznasDetailsScreen;
