import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import Message from "../../components/Message.tsx";
import Loader from "../../components/Loader.tsx";
import FormContainer from "../../components/FormContainer.jsx";
import { toast } from "react-toastify";
import {
  useUpdateGleznaPecNrMutation,
  useGetGleznaPecNrByIdQuery,
  useUploadGleznaPecNrImageMutation,
} from "../../slices/pakalpojumiApiSlice";

const GleznasPecNumuriemEditScreen = () => {
  const textAreaStyle = {
    backgroundColor: "#ccc8b8",
    color: "#0e1b21",
    letterSpacing: "1px",
    fontWeight: "bold",
  };

  const { id: gleznasId } = useParams();

  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [colors, setColors] = useState(0);
  const [size, setSize] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [description, setDescription] = useState("");
  const [order, setOrder] = useState(0);

  const {
    data: glezna,
    isLoading,
    refetch,
    error,
  } = useGetGleznaPecNrByIdQuery(gleznasId);

  const [updateGlezna, { isLoading: loadingUpdate }] =
    useUpdateGleznaPecNrMutation();

  const [uploadGleznaImage, { isLoading: loadingUpload }] =
    useUploadGleznaPecNrImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (glezna) {
      setImage(glezna.image || "");
      setTitle(glezna.title || "");
      setPrice(glezna.price || 0);
      setColors(glezna.colors || 0);
      setSize(glezna.size || "");
      setIsPublished(glezna.isPublished || false);
      setOrder(glezna.order || 0);
      setDescription(glezna.description || "");
    }
  }, [glezna]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedGlezna = {
      gleznasId,
      sadala: "gleznPecNum",
      image,
      title,
      price,
      colors,
      size,
      description,
      isPublished,
      order,
    };

    const result = await updateGlezna(updatedGlezna);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Glezna pēc numuriem ir saglabāta");
      refetch();
      navigate("/admin/gleznasPecNrList");
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadGleznaImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Container style={{ padding: "20px 12px 50px 12px" }}>
        <Link to="/admin/gleznasPecNrList/">
          <Button
            className="btn btn-primary custom-width-button "
            variant="primary"
          >
            Atpakaļ
          </Button>
        </Link>

        <FormContainer>
          {loadingUpdate && <Loader />}
          <h1>Labot gleznas pēc numuriem</h1>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="order" className="my-2">
                <Form.Label>Gleznas secība</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet gleznas secību"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="title" className="my-2">
                <Form.Label>Gleznas nosaukums</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Ievadiet gleznas nosaukumu"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="image" className="my-2">
                <Form.Label>Gleznas attēla atrašanās lokācija</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Attēla atrašanās vietu"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  style={textAreaStyle}
                  type="file"
                  placeholder="Choose file"
                  onChange={uploadFileHandler}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="price" className="my-2">
                <Form.Label>Gleznas cena</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet gleznas cenu"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="colors" className="my-2">
                <Form.Label>Gleznas krāsu skaits</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="number"
                  placeholder="Ievadiet gleznas krāsu skaitu"
                  value={colors}
                  onChange={(e) => setColors(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="size" className="my-2">
                <Form.Label>Gleznas izmērs</Form.Label>
                <Form.Control
                  required
                  style={textAreaStyle}
                  type="text"
                  placeholder="Ievadiet gleznas izmēru"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="isPublished" className="my-2">
                <Form.Label>Vai publicēt gleznu?</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={isPublished}
                  onChange={(e) => setIsPublished(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <Form.Group controlId="description" className="my-2">
                <Form.Label>Gleznas apraksts</Form.Label>
                <Form.Control
                  required
                  style={{ ...textAreaStyle, whiteSpace: "pre-wrap" }}
                  as="textarea"
                  rows={8}
                  spellCheck="false"
                  placeholder="Ievadiet gleznas aprakstu"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                className="btn btn-primary custom-width-button my-2"
              >
                Saglabāt informāciju
              </Button>
            </Form>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

export default GleznasPecNumuriemEditScreen;
