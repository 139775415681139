import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeScreenPost = ({ post }) => {
  return (
    <Card className="card-custom p-3 rounded post-bg">
      <Link to={`/pakalpojumi/${post.sadala}`}>
        <Card.Img className="rounded-0" src={post.image} variant="top" />
      </Link>

      <Card.Body>
        <Link to={`/pakalpojumi/${post.sadala}`}>
          <Card.Title as="div" className="post-title text-color">
            <strong>{post.title}</strong>
          </Card.Title>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default HomeScreenPost;
