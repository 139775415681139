import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useGetKarikaturasImagesQuery } from "../slices/pakalpojumiApiSlice.js";

const KarikaturasScreen = () => {
  const {
    data: karikaturas,
    isLoading,
    error,
  } = useGetKarikaturasImagesQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <h1 className="px-3 display-4">Karikatūras</h1>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Karikatūra</b> ir lieliska ideja <b>personalizētai dāvanai!</b>{" "}
              Tajā varu atspoguļot visas Tavas vēlmes – Tavus mīļos, viņu
              hobijus, aizraušanās, raksturojošus elementus, attēlot svarīgus
              dzīves notikumus, u.tml. Mākslā nav robežu un ar radošu pieeju ir
              iespējams attēlot gandrīz jebko! Tāpēc droši raksti man, pastāsti
              savu vīziju/vēlmi un es centīšos to visu ielikt foršā, zīmētā
              karikatūrā!
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Zīmēju ar krāsainajiem zīmuļiem un melno pildspalvu.{" "}
              <b>Zīmēju tikai uz papīra - ar roku</b> (uz dažādiem formātiem).
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Termiņi</b> – ļoti atkarīgs no karikatūras izmēra, sarežģītības
              līmeņa un Tev vēlamā datuma.
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Cenas</b> – atkarīgs no karikatūras formāta, cilvēku skaita,
              sarežģītības līmeņa, termiņa, u.tt. (Standarta A4 izmērs sākot no
              65 eiro).
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Droši raksti man un par visu vienosimies!
            </p>

            <Row className="g-2 px-3 my-3">
              <Gallery>
                {karikaturas
                  .filter((item) => item.isPublished == true)
                  .map((item) => (
                    <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                      <Item
                        cropped
                        original={item.image}
                        thumbnail={item.image}
                        width={item.width}
                        height={item.height}
                        alt={item.description}
                      >
                        {({ ref, open }) => (
                          <img
                            style={{
                              objectFit: "contain",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              margin: "0 0 10px 0",
                            }}
                            loading="lazy"
                            src={item.image}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                            alt={item.description}
                          />
                        )}
                      </Item>
                    </Col>
                  ))}
              </Gallery>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default KarikaturasScreen;
