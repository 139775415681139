import { ATSAUKSMES_URL, PRODUCTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: () => ({
        url: ATSAUKSMES_URL,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetReviewsQuery} =
reviewApiSlice;
